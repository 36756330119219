import React from "react"
import { getBgImage, Target } from "../../../utils"

const Fee = ({ target }: { target: Target }) => {
  const rows = [
    { left: "生活保護", center: "生活保護受給世帯", right: "0円" },
    { left: "低所得", center: "市町村民税非課税世帯\n（収入が概ね300万円/年以下の世帯）", right: "0円" },
    { left: "一般1", center: "市町村民税課税世帯\n（所得割16万円未満）\n（収入が概ね600万円/年以下の世帯）", right: "9,300円",},
    { left: "一般2", center: "上記以外", right: "37,200円" },
  ]
  return (
    <div className="mt-12 md:mt-16">
      <p>​障害者自立支援法に基づく利用料金の１割分の料金をいただきます。</p>
      <p>
        利用料金として負担いただく金額は、世帯所得に応じて上限額が定められています。
      </p>
      <table className="w-full mt-10 border">
        <tbody>
          <tr className={`${getBgImage(target)}`}>
            <th className="p-5">区分</th>
            <th>世帯の収入状況</th>
            <th>負担上限額</th>
          </tr>
          {rows.map(item => {
            return (
              <tr key={item.left} className="text-center">
                <td className="border">{item.left}</td>
                <td className="border">{item.center}</td>
                <td className="py-5 whitespace-pre-wrap border">
                  {item.right}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Fee
