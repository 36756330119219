import React from "react"
import { getBorderColor, getTextColor, Target } from "../../../utils"

const Procedure = ({ target }: { target: Target }) => {
  const items = [
    {
      title: "主治医に利用の相談をする",
      description:
        "就労継続支援B型事業所の利用を検討していることを主治医に伝え、主治医から見て就労が可能な状態であるか、障害者総合支援サービスを利用する必要性があるかという観点から意見を仰ぎます。",
    },
    {
      title: "市町村窓口で利用申請を行う",
      description: "お住まいの市町村窓口にて、就労継続支援B型事業所の利用申請を行います。",
    },
    {
      title: "相談支援事業所でサービス等利用計画書を作成してもらう",
      description:
        "就労継続支援B型事業所を利用する前に、障害者総合支援サービスを利用するにあたり「サービス等利用計画書」を作成してもらう必要があります。",
    },
    {
      title: "障害福祉サービス利用受給者証を受け取る",
      description:
        "就労継続支援B型事業所は障害者手帳がなくても利用することができますが、「障害福祉サービス利用受給者証」が必要となります。",
    },
    {
      title: "就労継続支援B型事業所と契約手続きを行う",
      description:
        "受給者証を受け取った後は、実際に利用する就労継続支援B型事業所との契約になります。",
    },
    {
      title: "ご契約・ご利用開始",
      description:
        "実際に契約が完了したら、利用がスタートとなります。",
    },
  ]
  return (
    <div className="mt-12 md:mt-16">
      {items.map((item, index) => {
        return (
          <div className="mb-10 md:mb-8 md:flex" key={item.title}>
            <div
              className={`flex items-center justify-center w-24 h-24 mx-auto text-3xl border-4 rounded-full ${getTextColor(
                target
              )} md:w-40 md:h-40 md:mx-0 ${getBorderColor(
                target
              )} border-opacity-20 shrink-0 font-kiwiMaru`}
            >
              {index + 1}
            </div>
            <div className="mt-8 text-center md:my-auto md:ml-16 md:text-left">
              <div className="text-xl font-kiwiMaru">{item.title}</div>
              <div className="whitespace-pre-wrap mt-7">{item.description}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Procedure
